<template>
  <div class="index_page">
    <!-- 主体内容 -->
    <!-- 导航、轮播如 -->
    <div class="center_plate flex">

      <div class="cp_navi" @mouseleave="categoryShow = !!0">
        <div v-for="(item, index) in categoryList" :key="index" class="cp_nivi_border flex">
          <div class="cp_navi_items cp_background" @mouseover="mouseIn(item)">
            <span>{{ item.name }}</span>
            <!-- <i class="el-icon-arrow-right"></i> -->
          </div>
          <div v-for="(items, indexs) in item.child" :key="indexs" class="cp_navi_item">
            <div @click="navigateTo(`/categoryShow/categoryShow/${items.id}/${items.name}`)">
              {{items.name}}{{items.child.length !=0 ? '('  + items.child.length + ')' : ''}}
            </div>
          </div>
          <!-- <div v-if="categoryShow" class="cp_navi_content">
            <div
              class="cp_navi_con_item"
              v-for="(items, indexs) in categoryChildlist"
              :key="indexs"
              @click="navigateTo(`/categoryShow/categoryShow/${items.id}`)"
            >
              {{ items.name }}
            </div>
          </div> -->
        </div>
      </div>
      <!-- <div class="box_carousel">
        <el-carousel height="460px">
          <el-carousel-item v-for="(item, index) in carouselList" :key="index">
            <el-image style="width:100%;height:100%" :src="item.picture" fit="none"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div> -->
    </div>
    <!-- 区块内容 -->
    <div class="section">
      <div class="sec_left">
        <div class="sec_top">
          <span>精品推荐</span>
          <div @click="navigateTo(`/goodsShow/goodsShow/${1}`)">
            更多<i class="el-icon-d-arrow-right"></i>
          </div>
        </div>
        <div class="sec_l_content">
          <goodsList :list="boutiqueGoodsList"></goodsList>
        </div>
      </div>
      <div class="sec_right">
        <div class="sec_top">
          <span>热销店铺</span>
          <div @click="navigateTo(`/search/search${'/undefined'}${'/1'}`)">更多<i class="el-icon-d-arrow-right"></i></div>
        </div>
        <div class="sec_r_content">
          <div
            class="sec_r_item"
            v-for="(item, index) in hotShopList"
            :key="index"
            @click="navigateTo(`/shopDetail/shopDetail/${item.id}`)"
          >
            <div class="sec_r_item_img">
              <img :src="common.setImgLocation(item.cover)" alt="" />
            </div>
            <div class="sec_r_item_title ellipsis-2">{{ item.store }}</div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="section" style="margin-top: 40px;">
      <div class="sec_left">
        <div class="sec_top">
          <span>最新商品</span>
          <div @click="navigateTo(`/goodsShow/goodsShow/${4}`)">
            更多<i class="el-icon-d-arrow-right"></i>
          </div>
        </div>
        <div class="sec_l_content">
          <goodsList1 :list="newGoodsList"></goodsList1>
        </div>
      </div>
      <div class="sec_right">
        <div class="sec_top">
          <span>最新加入</span>
          <div @click="navigateTo(`/search/search${'/undefined'}${'/1'}`)">更多<i class="el-icon-d-arrow-right"></i></div>
        </div>
        <div class="sec_r_content">
          <div
            class="sec_r_item"
            v-for="(item, index) in superShopList"
            :key="index"
            @click="navigateTo(`/shopDetail/shopDetail/${item.id}`)"
          >
            <div class="sec_r_item_img">
              <img v-if="item.cover!='' && item.cover != null && item.cover != 'null'" :src="common.setImgLocation(item.cover)" alt="" />
              <img v-else src="../../static/logo.png" alt="">
            </div>
            <div v-if="item.store!='' && item.store!=null" class="sec_r_item_title ellipsis-2">{{ item.store }}</div>
            <div v-else class="sec_r_item_title ellipsis-2">{{item.user.name}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import goodsList from "@/components/goodsList";
import goodsList1 from "@/components/goodsList1";
export default {
  components: { goodsList, goodsList1 },
  data() {
    return {
      categoryList: [], //分类
      categoryShow: !!0,
      categoryChildlist: [],
      carouselList: [], //轮播图
      boutiqueGoodsList: [], //精品推荐
      newGoodsList: [], //最新商品
      hotShopList: [], //热销店铺
      superShopList: [], //超级店铺
    };
  },
  methods: {
    mouseIn(e) {
      this.categoryShow = !0;
      this.categoryChildlist = e.child;
    },
    getData() {
      this.$http.IndexCarousel().then((res) => {
        this.carouselList = res.data.banner;
        this.hotShopList = res.data.rxdp;
        this.superShopList = res.data.zxjr_data;
      });
      // 获取分类
      this.$http.CategoryList().then((res) => {
        this.categoryList = res.data.goods_type.map((v) => {
          v.show = !!0;
          return v;
        });
        this.categoryList.push(
          {
            name: '商品检索',
            child: [
              {
                name: '最新发布',
                child: [],
                id: '1.1'
              },
              {
                name: '精品推荐',
                child: [],
                id: '1.2'
              },
              {
                name: '多次出价',
                child: [],
                id: '1.3'
              },
              {
                name: '一口价',
                child: [],
                id: '1.4'
              },
              {
                name: '保证金',
                child: [],
                id: '1.5'
              },
              {
                name: '50元以下',
                child: [],
                id: '1.6'
              },
              {
                name: '50-100元',
                child: [],
                id: '1.7'
              },
              {
                name: '100-300元',
                child: [],
                id: '1.8'
              },
              {
                name: '300元以上',
                child: [],
                id: '1.9'
              },
            ]
          }
        )
      });
      this.$http.IndexGoods("page=1&sx=1&shop_province=1").then((res) => {
        this.boutiqueGoodsList = res.data.goods;
      });
      this.$http.IndexGoods("page=1&sx=1&shop_province=4").then((res) => {
        this.newGoodsList = res.data.goods;
      });
    },
  },
  mounted() {
    this.getData();
  },
  created () {
    document.title = '中蟋网交易市场---专业蟋蟀蛐蛐活体及鸣虫用品用具交易交流平台！'
 },
};
</script>
<style lang="scss" scoped>
.index_page {
  padding-top: 20px;
}

.center_plate {
  width: 100%;
  padding: 20px 0;
  .cp_navi {
    width: 100%;
    margin: auto;
    position: relative;
    color: rgb(20, 20, 20);
    border-bottom: 1px solid #cfcfcf;
    .cp_nivi_border {
      border-top: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
    }
    .cp_background {
      background: #d5d5d5;
    }
    .cp_navi_item {
      align-items: center;
      justify-content: space-between;
      height: 42px;
      width: 78px;
      line-height: 42px;
      cursor: pointer;
      text-align: center;
    }
    .cp_navi_items {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      width: 135px;
      line-height: 42px;
      padding: 0 30px;
      cursor: pointer;
    }
    .cp_navi_item:hover {
      background-color: #ac3030;
    }
    .cp_navi_content {
      position: absolute;
      left: 234px;
      top: 0;
      z-index: 5;
      min-width: 370px;
      height: 460px;
      background-color: #fff;
      box-shadow: 0 0 5px #99a9bf;
      color: #000;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 18px;
      font-weight: 800;
      .cp_navi_con_item {
        width: 150px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border: 1px solid #c0c0c0;
        border-radius: 5px;
         cursor: pointer;
      }
      .cp_navi_con_item:hover {
        background-color: #e2e2e2;
      }
    }
  }
  .box_carousel {
    width: calc(100% - 234px);
    height: 460px;

    .imgs {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
.section {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  .sec_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #c0c0c0;
    div {
      cursor: pointer;
    }
  }
  .sec_left {
    width: 1050px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
  }
  .sec_right {
    width: 234px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
    .sec_r_content {
      padding: 10px 10px 0px 10px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .sec_r_item {
        width: 100px;
        height: 130px;
        cursor: pointer;
        .sec_r_item_img {
          margin: 0 auto;
          width: 90px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 70px;
            height: 70px;
          }
        }

        .sec_r_item_title {
          padding-top: 10px;
          width: 100%;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
}
</style>